<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img :src="app.appLogoImage" alt="logo" />
        <h2 class="brand-text text-primary ml-1">
          {{ app.appName }}<sup class="brand-trademark">&reg;</sup>
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" class="w-50" alt="Forgot password V2"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- Header -->
          <template v-if="!state.sent && !state.hasCode">
            <b-card-title title-tag="h2" class="font-weight-bold mb-50">
              Forgot Password? 🔒
            </b-card-title>
            <b-card-text  class="mb-2">
              Enter your username and we'll send you an email with a verification code to reset your password.
            </b-card-text>
          </template>
          <template  v-if="state.sent || state.hasCode">
            <b-card-title title-tag="h2" class="font-weight-bold mb-50">
              Change Password 🔐
            </b-card-title>
            <b-card-text class="mb-2">
              Enter the verification code and enter a new password.
            </b-card-text>
          </template>

          <b-alert :show="error.show" variant="danger">
            {{ error.text }}
          </b-alert>

          <!-- Step 1 -->
          <template v-if="!state.sent && !state.hasCode">
            <validation-observer ref="simpleRules" v-slot="{handleSubmit}">
              <b-form class="auth-forgot-password-form mt-2" @submit.prevent="handleSubmit(sendForgotPasswordCode)">
                <b-form-group label="Username" label-for="username">
                  <validation-provider #default="{ errors }" name="username" rules="required">
                    <b-form-input
                        id="username"
                        v-model="username" trim
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-row no-gutters>
                  <b-col class="pr-50">
                    <b-button :disabled="!username" variant="primary" type="submit" block>
                      Send verification code
                    </b-button>
                  </b-col>
                  <b-col class="pl-50">
                    <b-button :disabled="!username" variant="outline-primary" block @click="state.hasCode = true">
                      I already have a verification code
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </template>

          <!-- Step 2 -->
          <template v-if="state.sent || state.hasCode">
            <validation-observer ref="simpleRules" v-slot="{handleSubmit}">
              <b-form class="auth-forgot-password-form mt-2" @submit.prevent="handleSubmit(updatePassword)">
                <b-form-group label="Verification Code" label-for="code">
                  <validation-provider #default="{ errors }" name="Verification Code" rules="required">
                    <b-form-input
                        id="code"
                        v-model.trim="code"
                        :formatter="codeFormatter" lazy-formatter
                        :state="errors.length > 0 ? false:null"
                        placeholder="######"
                        autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <validation-provider v-slot="validationContext" vid="passwordNew" name="New Password" :rules="rules.passwordNew">
                  <b-form-group label-for="account-new-password" label="New Password" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          id="account-new-password"
                          v-model="passwordNew.value"
                          :type="passwordNew.type"
                          name="new-password"
                          placeholder="New Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            :icon="passwordNewIcon"
                            class="cursor-pointer"
                            @click="togglePasswordNew"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>

                <validation-provider v-slot="validationContext" vid="passwordNewConfirm" name="Confirm New Password" :rules="rules.passwordNewConfirm">
                  <b-form-group label-for="account-retype-new-password" label="Confirm New Password" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          id="account-confirm-new-password"
                          v-model="passwordNewConfirm.value"
                          :type="passwordNewConfirm.type"
                          name="confirm-new-password"
                          placeholder="New Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            :icon="passwordNewConfirmIcon"
                            class="cursor-pointer"
                            @click="togglePasswordRetype"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>

                <!-- Change Password -->
                <b-button type="submit" variant="primary" block>
                  Change Password
                </b-button>
              </b-form>
            </validation-observer>
          </template>

          <!-- Footer -->
          <b-button-group class="text-center mt-2 w-100">
            <b-button variant="link" :to="{name:'auth-login'}">
              Back to login
            </b-button>
            <b-button v-if="state.hasCode" variant="link" @click="backToStart">
              I don't have a verification code
            </b-button>
          </b-button-group>

<!--          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
            <b-link :to="backToStart">
              <feather-icon icon="ChevronLeftIcon" /> I wrong, I don't have a verification code
            </b-link>
          </p>-->
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {Auth} from 'aws-amplify';
import {$themeConfig} from '@themeConfig';

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      app: $themeConfig.app,
      img: {
        code: require('@/assets/images/illustration/forgot-password.svg'),
        reset: require('@/assets/images/illustration/verification-code.svg')
      },
      username: null,
      code: null,
      newPassword: null,
      error: {
        show: false,
        text: null
      },
      passwordNew: {
        value: '',
        type: 'password'
      },
      passwordNewConfirm: {
        value: '',
        type: 'password'
      },


      rules: {
        passwordNew: { required: true, min: 8, confirmed: { target: 'passwordNewConfirm'} },
        passwordNewConfirm: { required: true, min: 8},
      },
      state: {
        hasCode: false,
        sending: false,
        sent: false
      },
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      return this.state.sent ? this.img.reset : this.img.code
    },
    passwordNewIcon() {
      return this.passwordNew.type === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordNewConfirmIcon() {
      return this.passwordNewConfirm.type === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async sendForgotPasswordCode() {
      this.state.sending = true
      Auth.forgotPassword(this.username)
          .then(data => {
            this.state.sent = true
          })
          .catch(err => {
            this.error.show = true
            this.error.text = err.message
            console.error(err)
          });
    },
    async updatePassword() {
      Auth.forgotPasswordSubmit(this.username, this.code, this.passwordNew.value)
          .then(async () => {
            await this.$router.push({name: 'auth-login'})
          })
          .catch(err => {
            this.error.show = true
            this.error.text = err.message
            console.error(err)
          });
    },

    togglePasswordNew() {
      this.passwordNew.type = this.passwordNew.type === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.this.passwordNewConfirm.type = this.this.passwordNewConfirm.type === 'password' ? 'text' : 'password'
    },

    codeFormatter(value) {
      return value.trim()
    },
    backToStart() {
      this.state.hasCode = false
      this.state.sent = false
      this.state.sending = false
    },

    /** Validation */
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
